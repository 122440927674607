import React ,{useState,useEffect} from "react"
import { graphql } from "gatsby"

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight ,faChevronLeft,faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player/lazy'
interface dataI{
    title: String;

}
const Proyectos = ({data})=>{
    const [dataQ,setdataQ] = useState({})
    useEffect(() => {
        setdataQ(data.allStrapiProyectos.edges[0].node)
    }, [])
    return(
        <div id="proyectoPost">
          <div className="izq">
            <div style={{width:"95%"}}>
              <Carousel slidesPerPage={1} infinite={true} arrows={true} draggable={true}  
              arrowLeft={<FontAwesomeIcon style={{width:"1.5em", color:"white",cursor: "pointer"}} icon={faChevronLeft} />}
              arrowRight={<FontAwesomeIcon style={{width:"1.5em", color:"white",cursor: "pointer"}} icon={faChevronRight} />}
                addArrowClickHandler={true}
                >
                {dataQ.images?.map(e=>{
                if(e.ext===".jpg"){
                return(
                <img className='image' src={e.url} />
                )
                }else if(e.ext===".mp4"){
                return (
                <div className='content'>
                  <ReactPlayer playing={true} muted={true} url={e.url} />
                </div>

                )
                }

              })}
              </Carousel>
            </div>

          </div>
          <div className="der">
            <h1>
              {dataQ.title}
            </h1>
          </div>
          <div className="texto">
            <p>
              {dataQ.description}
            </p>
          </div>
        </div>
    )
}

export default Proyectos
export const pageQuery = graphql`
query ArticleTemplate($id: String!) {
  allStrapiProyectos(filter: {id: {eq: $id}}) {
    edges {
      node {
        id
        title
        images {
          url
          ext
        }
        description
      }
    }
  }
}
`